import { motion } from 'framer-motion';
import Head from 'next/head';
import { useRecoilValue } from 'recoil';
import { TailSpin } from 'react-loader-spinner';
import { isBusySelector } from '@/stores/appStore';
import { useLocalization } from '@/services/localizationService';
import settingsState from '@/stores/settingsStore';
import { useCanonicalUrl } from '@/core/seo';
import { ReactNode } from 'react';

export function PageLayout({
  title,
  children,
  shouldAvoidIndexing = false,
}: {
  title: string;
  children: ReactNode;
  shouldAvoidIndexing?: boolean;
}) {
  const isBusy = useRecoilValue(isBusySelector);
  const settings = useRecoilValue(settingsState);
  const canonicalUrl = useCanonicalUrl();
  const { isReady } = useLocalization();

  return (
    <>
      <Head>
        <title>Remini Web | {title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {shouldAvoidIndexing && (
          <meta name="robots" content="noindex,nofollow" />
        )}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href={canonicalUrl} />;
      </Head>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: settings.areReady ? 1 : 0 }}
        exit={{ opacity: 0 }}
        className="w-full h-full"
        aria-busy={isBusy}
        aria-live="polite"
      >
        {children}
      </motion.div>
      {(!settings.areReady || !isReady) && (
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-black-background">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'circIn', delay: 0.5 }}
          >
            <TailSpin
              color="white"
              height="80"
              width="80"
              aria-label="loading"
            />
          </motion.div>
        </div>
      )}
    </>
  );
}
